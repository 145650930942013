@import 'fonts';
@import '~bootstrap/scss/functions';
@import 'variables_app_b5';
@import '~bootstrap/scss/bootstrap';
@import '~izitoast/dist/css/iziToast.min.css';
@import '~vue-select/src/scss/vue-select.scss';
@import '~flatpickr/dist/flatpickr.min.css';
@import 'ql';
@import 'border-utils';

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

#app {
  flex: 1 0 auto;
}

#hero {
  position: relative;
}

#hero:after {
  content: ' ';
  background-color: $purple;
  position: absolute;
  top: -500px;
  bottom: -150px;
  right: 0;
  z-index: -1;
  width: 100%;
  border-radius: 0;
  transform: skewY(-5deg);
  @include media-breakpoint-up(md) {
    top: -210px;
  }
}

.main-nav {
  font-size: 0.85rem;
  align-items: center;

  .navbar-nav {
    align-items: center;
  }
}

.bg-purple-gradient {
  background-image: linear-gradient(to right, $purple, rgba($purple, 0.9));
}

.feature-icon {
  background-color: rgba($pink, 0.15);
  color: $pink;
  border-radius: 50%;
  width: 50px;
  height: 50px;

  i {
    font-size: 1.4rem;
  }
}

.badge-purple {
  color: #ffffff;
  background-color: $purple;
}

.btn-round {
  font-size: 0.85rem;
  border-radius: 30px;
  padding: 12px 25px;
  font-weight: 700;
  text-transform: uppercase;
}

.navbar-nav {
  li {
    a:not(.dropdown-item) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}

.footer {
  background-color: rgba($purple, 0.04);
  flex-shrink: 0;
}

.card {
  text-decoration: none;

  .card-header {
    padding-top: 20px 25px;
    background: $white;
    align-items: center;
    border-bottom: 1px solid rgba($black, 0.04);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: rgba($black, 0.6);
      margin: 0;
    }
  }
  .card-body {
    padding-top: 20px 25px;
  }
  .card-footer {
    padding-top: 20px 25px;
  }
}

a.card {
  transition: all 0.3s ease-in-out;

  &:hover {
    text-decoration: none;
    transform: scale(1.014);
  }
}

.icon-box {
  width: 50px;
  height: 50px;
  border-radius: $border-radius;
}

.card-todo {
  h4 {
    color: rgba($black, 0.6);
  }
}

.fixed-message {
  position: fixed;
  top: 5vh;
  left: 20%;
  width: 60%;
}

.jumbotron {
  background-color: inherit;
  margin-bottom: 0;
}

.grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.img-shadow {
  box-shadow: 0 10px 50px -5px rgba(51, 71, 91, 0.4);
}

.heartbeat {
  animation: heartbeat 2s infinite;
  cursor: pointer;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

@mixin border-left-color {
  @each $name, $hex in $theme-colors {
    &-#{$name} {
      border-left-color: $hex;
      border-left-width: 4px;
    }
  }
}

.border-left {
  @include border-left-color();
}

.blog-post {
  font-size: 1.3rem;
  color: rgb(68, 68, 68);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 30px;
  }

  p,
  ul,
  ol {
    font-family: 'Frank Ruhl Libre', serif;
    margin-bottom: 30px;
  }
}

.ql-container {
  max-height: 80vh;
}

.ql-snow .ql-editor, .preview-container {
  a.btn {
    color: $white;
    text-decoration: none;
    &.btn-primary {
      background: $blue;
      border-color: $blue;
    }
    &.btn-warning {
      color: $black;
    }
    &.btn-teal {
      color: $black;
    }
    &.btn-info {
      color: $black;
    }
    &.btn-light {
      color: $black;
    }
    &.btn-outline-primary {
      color: $blue;
      background: #fff;
      border-color: $blue;
    }
    &.btn-outline-info {
      color: $info;
    }
    &.btn-outline-secondary {
      color: $secondary;
    }
    &.btn-outline-success {
      color: $success;
    }
    &.btn-outline-warning {
      color: $warning;
    }
    &.btn-outline-danger {
      color: $danger;
    }
    &.btn-outline-indigo {
      color: $indigo;
    }
    &.btn-outline-dark {
      color: $dark;
    }
    &.btn-outline-purple {
      color: $purple;
    }
    &.btn-outline-teal {
      color: $teal;
    }
    &.btn-outline-pink {
      color: $pink;
    }
    &.ql-snow {
      text-decoration: none;
    }
  }
}

.btn-white {
  background-color: #fff;
}
.btn-white:hover {
  background-color: rgba(#fff, 0.8);
}

.no-opacity {
  opacity: 1 !important;
}
.grecaptcha-badge {
  visibility: collapse !important;
}

// .breadcrumb {
//   text-transform: uppercase;
// }

.vs__dropdown-toggle {
  background: white;
  padding: 4px 0 4px 0;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.15s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.component-slide-right-enter-active,
.component-slide-right-leave-active {
  transition: all 0.15s ease-in;
}
.component-slide-right-enter, .component-slide-right-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  transform: translateX(200px);
  opacity: 0;
}

.flip-list-move {
  transition: transform 0.5s;
}

.gallery-block {
  padding: 0;
  margin: 0;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    max-height: 30rem;
    display: flex;
    flex-wrap: wrap;
  }

  .gallery-block__item {
    position: relative;
    min-height: 25rem;
    list-style-type: none;
    @include media-breakpoint-up(md) {
      flex: 1;
    }

    img {
      width: 100%;
      min-height: 25rem;
      object-fit: cover;
    }
  }
}

.btn-link.active {
  border-bottom: 2px solid $primary;
  border-radius: 0;
}

.flatpickr-input.form-control[readonly] {
  background-color: $white;
}

.ribbon {
  margin: 0;
  position: absolute;
  top: -0.25rem;
  right: -0.25rem;
  outline: 1px solid transparent;
  width: 7rem;
  height: 7rem;
  overflow: hidden;
  span {
    background-color: #e66760;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    font-size: 0.75rem;
    position: absolute;
    top: 2.425rem;
    right: -4.375rem;
    padding: 0.0625rem 3.125rem 0.0625rem 3.125rem;
    width: 15rem;
    z-index: 2;
    transform: rotate(45deg);
    text-align: center;
  }
  &::before {
    content: '';
    background-color: #df3d34;
    display: block;
    position: absolute;
    height: 0.25rem;
    width: 1rem;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &::after {
    content: '';
    background-color: #df3d34;
    display: block;
    position: absolute;
    height: 1rem;
    width: 0.25rem;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}

.pe-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.table > :not(:first-child) {
  border-top: none !important;
}

.vue-tags-input {
  max-width: 100% !important;
}
.vue-tags-input .ti-input {
  border-radius: 4px !important;
}
