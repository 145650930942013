strong {
	font-weight: 700;
}
.serif, .ql-font-serif {
	font-family: 'Frank Ruhl Libre', serif;
}
pre.ql-syntax {
	background-color: #23241f;
	color: #f8f8f2;
	overflow: visible;
}
pre.ql-syntax {
	background-color: #23241f;
	color: #f8f8f2;
	overflow: visible;
}
.ql-align-center {
	@extend .text-center
}
.ql-align-right {
	@extend .text-end
}
.ql-align-justify {
	text-align: justify;
}
.ql-align-left {
	@extend .text-end
}
.ql-size-small {
	font-size: $font-size-sm;
}
.ql-size-large {
	font-size: $h4-font-size;
	// @extend .lead !optional
}
.ql-size-huge {
	font-size: $h2-font-size;
}
.ql-font-monospace {
	@extend .font-monospace
}

.ql-picker {
	&.ql-bolder {
	   width: 100px;
	  .ql-picker-item,
	  .ql-picker-label {
		&::before {
		  content: 'Normal';
		}
		&[data-value='light']::before {
		  content: 'Light';
		  font-weight: 300;
		}
		&[data-value='bold']::before {
		  content: 'Bold';
		  font-weight: 700;
		}
		&[data-value='bolder']::before {
		  content: 'Bolder';
		  font-weight: 900;
		}
	  }
	}
	&.ql-display {
	   width: 100px;
	  .ql-picker-item,
	  .ql-picker-label {
		&::before {
		  content: 'Normal';
		}
		&[data-value='1']::before {
		  content: 'Display 1';
		}
		&[data-value='2']::before {
		  content: 'Display 2';
		}
		&[data-value='3']::before {
		  content: 'Display 3';
		}
		&[data-value='4']::before {
		  content: 'Display 4';
		}
		&[data-value='5']::before {
		  content: 'Display 5';
		}
		&[data-value='6']::before {
		  content: 'Display 6';
		}
	  }
	}
  }